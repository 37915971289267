.body {
  background: linear-gradient(180deg, #181859 0%, #327bcc 71.44%);
}

.MuiInputBase-root {
  display: block;
  .MuiAutocomplete-clearIndicator {
    margin-right: 10px;
  }
  #condition-typeahead,
  #specialty-typeahead,
  #condition-edit-typeahead,
  #specialty-edit-typeahead {
    // text-transform: capitalize;
  }
}

.MuiAutocomplete-popper {
  .MuiPaper-root {
    .MuiAutocomplete-listbox {
      .MuiAutocomplete-option {
        display: block;
        text-transform: capitalize;
      }
    }
  }
}

.MuiDrawer-root {
  display: none !important;
}
